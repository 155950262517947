import * as React from "react";
import { useState, useEffect, useRef, lazy } from "react";
import { Link } from "gatsby";
import logo from "../images/icon.svg";
import Menu from "./Menu";
import Burger from "./Burger";
import useOnClickOutside from "../hooks";
import useIntersectionObserver from '../useIntersectionObserver';

const Icon = lazy(() => import('./Icon'));

const Layout = ({ location, title, children, slug }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  const [open, setOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

  useOnClickOutside(node, () => setOpen(false));

  useEffect(() => {
    const handleScroll = () => setNavbar(window.scrollY >= 50);

    // Run the handleScroll function initially to set the state based on the current scroll position
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const classWrapper = `global-wrapper${navbar ? " scrolled" : ""}${slug ? ` ${slug}` : ""}`;

  useIntersectionObserver();

  const dashboardUrl = process.env.GATSBY_DASHBOARD_APP_URL;

  return (
    <div open={open} className={classWrapper} data-is-root-path={isRootPath}>
      <Burger
        open={open}
        setOpen={setOpen}
        aria-controls={menuId}
        ariaLabel="Toggle menu"  // Pass aria-label correctly
      />
      <header className="global-header">
        <div className="global-header-wrapper">
          <div className="main-heading">
            <Link to="/">
              <img src={logo} alt="logo" width={40} height={40} />
              {isRootPath ? (
                <h1>{title}</h1>
              ) : (
                <strong>{title}</strong>
              )}
            </Link>
          </div>
          <nav className="display-none-on-tablet">
            <ul>
              <li><Link to="/" className={location.pathname === "/" ? "current" : ""}>Home</Link></li>
              <li><Link to="/dashboard" className={location.pathname === "/dashboard/" ? "current" : ""}>Features</Link></li>
              <li><Link to="/posts" className={location.pathname === "/posts/" ? "current" : ""}>Posts</Link></li>
              <li><Link to="/about" className={location.pathname === "/about/" ? "current" : ""}>About</Link></li>
              <li><Link to={dashboardUrl} className="btn">Dashboard</Link></li>
            </ul>
          </nav>
        </div>
      </header>
      <Menu open={open} id={menuId} setOpen={setOpen} />
      <main>{children}</main>
      <footer className="global-footer">
        <p>© {new Date().getFullYear()}, Formula 1 Dashboard</p>
        <div className="social-media display-none-on-mobile">
          <ul>
            <li><p>Follow us <span className="hero-social-border"></span></p></li>
            <li><a href="https://twitter.com/F1_Dashboard" target="_blank" rel="noreferrer" aria-label="Follow us on Twitter">𝕏</a></li>
            <li>
              <a href="https://www.reddit.com/user/FormulaOneDashboard/" target="_blank" rel="noreferrer" aria-label="Follow us on Reddit">
                <Icon platform="reddit" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/f1_dashboard/" target="_blank" rel="noreferrer" aria-label="Follow us on Instagram">
                <Icon platform="instagram" />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Layout;