import * as React from "react";
import { Link, graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import Layout from "../components/layout";
import Seo from "../components/seo";

const DashboardPageTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const title = data?.prismicDashboardPage?.data?.title?.text || "Dashboard";
  const subtitle = data?.prismicDashboardPage?.data?.subtitle?.html;
  const cta = data?.prismicDashboardPage?.data?.cta || "Explore F1 Data";
  const feature = data?.prismicDashboardPage?.data?.body || [];
  const dashboardAppUrl = process.env.GATSBY_DASHBOARD_APP_URL;

  return (
    <Layout location={location} title={siteTitle} slug="dashboard">
      <div className="container" style={{ backgroundImage: `url(${data?.prismicDashboardPage?.data?.bg_image?.url})` }}>
        <div className="infos">
          <h1>{title}</h1>
          <div className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
          <Link to={dashboardAppUrl} className="btn">{cta} <span>→</span></Link>
        </div>
        <div className="features">
          {feature.map((feature) => (
            <>
              <div className="feature-section">
                <h2>{feature.primary.feature_section_title.text}</h2>
                <div dangerouslySetInnerHTML={{ __html: feature.primary.feature_section_description.html }} />
              </div>
              <div className="feature-items">
                {feature.items.map((item, index) => (
                  <div key={index} className="feature-item">
                    <img src={item.feature_icon.url} alt={item.feature_title} />
                    <strong>{item.feature_title}</strong>
                    <div dangerouslySetInnerHTML={{ __html: item.feature_description.html }} />
                  </div>
                ))}
              </div>
            </>
          ))}
          <Link to={dashboardAppUrl} className="btn">{cta} <span>→</span></Link>
        </div>
      </div>
    </Layout>
  );
};

export const Head = ({ data }) => {
  const title = "Ultimate F1 dashboard";
  const description = "Dive into F1 2024 season data: drivers' and constructors' rankings, predictions, pit stops, tech updates, crash costs, used elements and much more.";

  return <Seo title={title} description={description} />;
};

export const dashboardPageQuery = graphql`
  query DashboardPageQuery {
    prismicDashboardPage {
      _previewable
      data {
        title {
          text
        }
        subtitle {
          html
        }
        bg_image {
          url
        }
        body {
          ... on PrismicDashboardPageDataBodyFeature {
            id
            primary {
              feature_section_description {
                html
              }
              feature_section_title {
                text
              }
            }
            items {
              feature_description {
                html
              }
              feature_icon {
                url
              }
              feature_title
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default withPrismicPreview(DashboardPageTemplate);