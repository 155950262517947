import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Menu = ({ open, setOpen, ariaLabel, ...props }) => {
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  // Check if window exists before accessing location
  const pathname = typeof window !== "undefined" ? window.location.pathname : "";

  // Close the menu if the current page is clicked
  const handleLinkClick = (e) => {
    if (e.target.classList.contains('current')) {
      setOpen(!open);
    }
  };

  const dashboardUrl = process.env.GATSBY_DASHBOARD_APP_URL;

  return (
    <>
      <nav open={open} aria-hidden={!isHidden} {...props}>
        <ul>
          <li>
            <Link to="/" tabIndex={tabIndex} className={pathname === "/" ? "current" : ""} onClick={handleLinkClick}>Home</Link>
          </li>
          <li>
            <Link to="/dashboard" tabIndex={tabIndex} className={pathname === "/dashboard/" ? "current" : ""} onClick={handleLinkClick}>Features</Link>
          </li>
          <li>
            <Link to="/posts" tabIndex={tabIndex} className={pathname === "/posts/" ? "current" : ""} onClick={handleLinkClick}>Posts</Link>
          </li>
          <li>
            <Link to="/about" tabIndex={tabIndex} className={pathname === "/about/" ? "current" : ""} onClick={handleLinkClick}>About</Link>
          </li>
          <li>
            <Link to={dashboardUrl} tabIndex={tabIndex} className='btn' onClick={handleLinkClick}>Dashboard</Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Menu;