import * as React from "react";
import { useEffect } from "react";
import { Link, graphql } from "gatsby";
import kebabCase from "lodash/kebabCase";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { formatRelativeDate } from "../utils/formatDate";

const BlogPostTemplate = ({ data, location }) => {
  const { previous, next, site, prismicPost: post } = data;
  const siteTitle = site.siteMetadata?.title;
  const slices = post.data.body;
  const backgroundImage = getImage(post.data.cover.gatsbyImageData);
  const previousPostBackgroundImage = previous ? getImage(previous.data.cover.gatsbyImageData) : null;
  const nextPostBackgroundImage = next ? getImage(next.data.cover.gatsbyImageData) : null;
  const postDate = post.data.post_date;
  const lastUpdateDate = post.data.last_update_date;

  useEffect(() => {
    const tableContainers = document.querySelectorAll('.table-container');

    // Define checkScroll outside of the loop to be accessible in multiple events
    const checkScroll = (container) => {
      if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        container.classList.add('no-scroll-gradient');
      } else {
        container.classList.remove('no-scroll-gradient');
      }
    };

    // Function to apply checkScroll to all containers
    const handleScrollAndResize = () => {
      tableContainers.forEach(container => checkScroll(container));
    };

    // Add both scroll and resize event listeners
    tableContainers.forEach(container => {
      container.addEventListener('scroll', () => checkScroll(container));
    });
    window.addEventListener('resize', handleScrollAndResize);

    // Initial check in case the table is already scrolled or window resized
    handleScrollAndResize();

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      tableContainers.forEach(container => {
        container.removeEventListener('scroll', () => checkScroll(container));
      });
      window.removeEventListener('resize', handleScrollAndResize);
    };
  }, []); // Empty dependency array makes this run only on component mount

  return (
    <Layout location={location} title={siteTitle} slug="post">
      <article className="blog-post" itemScope itemType="http://schema.org/Article">
        <header>
          <GatsbyImage
            image={backgroundImage}
            alt={post.data.cover.alt}
            className="hero-bg"
            loading="eager" // Ensure this image loads immediately
          />
          <div className="container">
            <div className="inner">
              <h1 itemProp="headline">{post.data.title.text}</h1>

              <ul className="post-list tags">
                {post.tags.map((tag, index) => (
                  <li key={`${tag}-${index}`}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                  </li>
                ))}
              </ul>
              <p className="excerpt">{post.data.excerpt}</p>
              <small>
                Published on {formatRelativeDate(postDate)}
                {lastUpdateDate && (
                  <>
                    , updated on {formatRelativeDate(lastUpdateDate)}
                  </>
                )}
              </small>
            </div>
          </div>
          <div className="arrow display-none-on-tablet"></div>
        </header>

        <div itemProp="articleBody" className="blog-post-body container content">
          {slices.map((slice, index) => {
            if (slice.slice_type === "text") {
              return (
                <section
                  key={`text-${index}-${slice.id}`}
                  dangerouslySetInnerHTML={{
                    __html: slice.primary.text.html || "",
                  }}
                />
              );
            }

            if (slice.slice_type === "image") {
              return (
                <section key={`image-${index}-${slice.id}`} className="image-slice">
                  <div dangerouslySetInnerHTML={{ __html: slice.primary.image_content.html }} />
                  {slice.items.map((item, itemIndex) => {
                    const altText = item.image.alt || "";

                    return (
                      <>
                        <GatsbyImage
                          key={`image-${index}-${itemIndex}`}
                          image={getImage(item.image.gatsbyImageData)}
                          alt={altText}
                          className="blog-post-image"
                        />
                        <div dangerouslySetInnerHTML={{ __html: item.caption.html }} />
                      </>
                    );
                  })}
                </section >
              );
            }

            if (slice.slice_type === "embed_video") {
              const embedCode = slice.items[0].embed_code.text || "";
              console.log("embedCode: " + embedCode);
              return (
                <section
                  className="embed-video"
                  key={`embed_video-${index}-${slice.id}`}
                  dangerouslySetInnerHTML={{
                    __html: embedCode,
                  }}
                />
              );
            }

            const teamLogos = {
              "Red Bull Racing": "https://images.prismic.io/formula-1-dashboard/ZvB2LLVsGrYSvp5M_redbull-color.png?auto=format,compress",
              "Ferrari": "https://images.prismic.io/formula-1-dashboard/ZvB2MrVsGrYSvp5S_ferrari-color.png?auto=format,compress",
              "Mercedes": "https://images.prismic.io/formula-1-dashboard/ZvB2LrVsGrYSvp5O_mercedes-color.png?auto=format,compress",
              "McLaren": "https://images.prismic.io/formula-1-dashboard/ZvB2L7VsGrYSvp5P_mclaren-color.png?auto=format,compress",
              "Alpine": "https://images.prismic.io/formula-1-dashboard/ZvB2NLVsGrYSvp5U_alpine-color.png?auto=format,compress",
              "Aston Martin": "https://images.prismic.io/formula-1-dashboard/ZvB2M7VsGrYSvp5T_aston-martin-color.png?auto=format,compress",
              "Kick Sauber": "https://images.prismic.io/formula-1-dashboard/ZvB2MLVsGrYSvp5Q_kick-sauber-color.png?auto=format,compress",
              "Haas": "https://images.prismic.io/formula-1-dashboard/ZvB2MbVsGrYSvp5R_haas-color.png?auto=format,compress",
              "Williams": "https://images.prismic.io/formula-1-dashboard/ZvB2K7VsGrYSvp5L_williams-color.png?auto=format,compress",
              "RB": "https://images.prismic.io/formula-1-dashboard/ZvB2LbVsGrYSvp5N_RB-color.png?auto=format,compress"
            };

            if (slice.slice_type === "table") {
              return (
                <section key={`table-${index}-${slice.id}`} className="table-slice driver-standings">
                  <div dangerouslySetInnerHTML={{ __html: slice.primary.driver_standings_content.html }} />
                  <table>
                    <thead>
                      <tr>
                        <th>Pos.</th>
                        <th>Driver</th>
                        <th className="display-none-on-mobile">Team</th>
                        <th>Pts</th>
                        <th>Evo.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {slice.items.map((item, itemIndex) => {
                        // Determine the CSS class based on the evolution value
                        const evolutionClass = item.evolution > 0 ? "positive-evolution" : item.evolution < 0 ? "negative-evolution" : "";

                        // Get the logo URL for the team
                        const teamLogo = teamLogos[item.teams];

                        // Format evolution value with arrow and absolute value
                        let formattedEvolution;
                        if (item.evolution > 0) {
                          formattedEvolution = `▲ ${item.evolution}`;
                        } else if (item.evolution < 0) {
                          formattedEvolution = `▼ ${Math.abs(item.evolution)}`;
                        } else {
                          formattedEvolution = `-`;
                        }

                        return (
                          <tr key={`row-${itemIndex}`}>
                            <td>{itemIndex + 1}</td> {/* Rank column */}
                            <td className="driver">
                              <img
                                src={teamLogo}
                                alt={`${item.teams} logo`}
                                className="display-on-mobile display-none-on-tablet"
                              />
                              {item.drivers}
                            </td>
                            <td className="team display-none-on-mobile">
                              <img
                                src={teamLogo}
                                alt={`${item.teams} logo`}
                              />
                              <span>{item.teams}</span>
                            </td>
                            <td>{item.points}</td>
                            <td className={evolutionClass}>{formattedEvolution}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {slice.primary.bottom_link?.uid && (
                    <Link to={`/${slice.primary.bottom_link.uid}`} className="btn-tfoot">
                      {slice.primary.bottom_link_label}
                    </Link>
                  )}
                </section>
              );
            }

            if (slice.slice_type === "race_results") {
              let posDisplay = 0;
              return (
                <section key={`table-${index}-${slice.id}`} className="table-slice race-results">
                  <div dangerouslySetInnerHTML={{ __html: slice.primary.race_result_content.html }} />
                  <table>
                    <thead>
                      <tr>
                        <th>Pos.</th>
                        <th>Driver</th>
                        <th className="display-none-on-mobile">Team</th>
                        <th className="time">Time</th>
                        <th><span className="best-lap">Best lap</span></th>
                        <th>Pts</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        slice.items.map((item, itemIndex) => {
                          // Get the logo URL for the team
                          const teamLogo = teamLogos[item.teams];

                          // Check if DQ (disqualified) or NC (no classification) flags are true
                          let currentPosDisplay;

                          if (item.dq) {
                            currentPosDisplay = "DQ"; // Set to "DQ"
                          } else if (item.nc) {
                            currentPosDisplay = "NC"; // Set to "NC"
                          } else {
                            posDisplay++; // Only increment when it's neither DQ nor NC
                            currentPosDisplay = posDisplay;
                          }

                          return (
                            <tr key={`row-${itemIndex}`}>
                              <td>{currentPosDisplay}</td>
                              <td className="driver">
                                <img
                                  src={teamLogo}
                                  alt={`${item.teams} logo`}
                                  className="display-on-mobile display-none-on-tablet"
                                />
                                {item.drivers}
                              </td>
                              <td className="team display-none-on-mobile">
                                <img
                                  src={teamLogo}
                                  alt={`${item.teams} logo`}
                                />
                                <span>{item.teams}</span>
                              </td>

                              <td className="time">{item.time}</td>
                              <td className="fastest-lap">
                                {item.fastest_lap && (
                                  <img
                                    src="https://images.prismic.io/formula-1-dashboard/ZxJCZIF3NbkBXtkV_fastest-lap-icon.png?auto=format,compress"
                                    alt="Fastest lap"
                                    className="fastest-lap"
                                  />
                                )}
                              </td>
                              <td>
                                {item.points}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {slice.primary.bottom_link?.uid && (
                    <Link to={`/${slice.primary.bottom_link.uid}`} className="btn-tfoot">
                      {slice.primary.bottom_link_label}
                    </Link>
                  )}
                </section>
              );
            }

            if (slice.slice_type === "tech_updates") {
              return (
                <section key={`table-${index}-${slice.id}`} className="table-slice tech-updates">
                  <div className="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th>Team</th>
                          <th>Component</th>
                          <th>Type</th>
                          <th>Primary reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {slice.items.map((item, itemIndex) => {
                          // Get the logo URL for the team
                          const teamLogo = teamLogos[item.teams];

                          return (
                            <tr key={`row-${itemIndex}`}>
                              <td className="team">
                                <img
                                  src={teamLogo}
                                  alt={`${item.teams} logo`}
                                />
                                <span className="display-none-on-mobile">{item.teams}</span>
                              </td>
                              <td>{item.components}</td>
                              <td className={`tech-update-type ${item.type === "Performance" ? "performance" : ""} ${item.type === "Circuit specific" ? "circuit-specific" : ""}`}>
                                {item.type}
                              </td>
                              <td>{item.primary_reasons}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </section>
              );
            }

            if (slice.slice_type === "chart") {
              const Chart = React.lazy(() => import("../components/Chart"));

              try {
                const chartTitle = slice.primary.chart_title;
                const chartType = slice.primary.chart_type || "line";
                const chartData = JSON.parse(slice.primary.chart_data || "{ }");
                const chartLabels = JSON.parse(slice.primary.chart_labels || "[]");
                const chartIndexAxis = slice.primary.index_axis || "x";
                const chartDisplayLegend = slice.primary.display_legend;
                const chartLegendLabel = slice.primary.legend_label;
                const chartLegendPosition = slice.primary.legend_position || "right";
                const chartLabelsAnchor = slice.primary.labels_anchor || "center";
                const chartLabelsAlignment = slice.primary.labels_alignment || "top";
                const chartLabelsOffset = slice.primary.labels_offset || "0";
                const chartScaleXdisplay = slice.primary.scale_x_display;
                const chartScaleXoffset = slice.primary.scale_x_offset;
                const chartScaleXgrid = slice.primary.scale_x_grid;
                const chartScaleXborder = slice.primary.scale_x_border;
                const chartScaleYdisplay = slice.primary.scale_y_display;
                const chartScaleYoffset = slice.primary.scale_y_offset;
                const chartScaleYgrid = slice.primary.scale_y_grid;
                const chartScaleYborder = slice.primary.scale_y_border;
                const chartPrimaryColor = slice.primary.primary_color;
                const chartSecondaryColor = slice.primary.secondary_color;

                return (
                  <section key={`chart-${index}-${slice.id}`} className="chart">
                    <h2>{chartTitle}</h2>
                    <React.Suspense fallback={<div>Loading chart...</div>}>
                      <Chart
                        data={chartData}
                        labels={chartLabels}
                        type={chartType}
                        indexAxis={chartIndexAxis}
                        displayLegend={chartDisplayLegend}
                        legendLabel={chartLegendLabel}
                        legendPosition={chartLegendPosition}
                        labelsAnchor={chartLabelsAnchor}
                        labelsAlignment={chartLabelsAlignment}
                        labelsOffset={chartLabelsOffset}
                        scaleXdisplay={chartScaleXdisplay}
                        scaleXoffset={chartScaleXoffset}
                        scaleXgrid={chartScaleXgrid}
                        scaleXborder={chartScaleXborder}
                        scaleYdisplay={chartScaleYdisplay}
                        scaleYoffset={chartScaleYoffset}
                        scaleYgrid={chartScaleYgrid}
                        scaleYborder={chartScaleYborder}
                        primaryColor={chartPrimaryColor}
                        secondaryColor={chartSecondaryColor} />
                    </React.Suspense>
                  </section>
                );
              } catch (error) {
                console.error("Error parsing chart data:", error);
                return <p key={`chart-error-${index}-${slice.id}`}>Error loading chart data.</p>;
              }
            }
            return null;
          })}
        </div>
      </article>
      <nav className="blog-post-nav">
        <ul>
          <li>
            {previous && (
              <>
                <Link to={previous.url} rel="prev">
                  <span>← Previous</span><strong>{previous.data.title.text}</strong>
                </Link>
                <GatsbyImage
                  image={previousPostBackgroundImage}
                  alt={previous.data.cover.alt}
                  className="previous-bg"
                />
              </>
            )}
          </li>
          <li>
            {next && (
              <>
                <Link to={next.url} rel="next">
                  <span>Next →</span>
                  <strong>{next.data.title.text}</strong>
                </Link>
                <GatsbyImage
                  image={nextPostBackgroundImage}
                  alt={next.data.cover.alt}
                  className="next-bg"
                />
              </>
            )}
          </li>
        </ul>
      </nav>
    </Layout >
  );
};

export const Head = ({ data }) => {
  const { title, excerpt } = data?.prismicPost?.data || {};
  const image = data?.prismicPost?.data.cover.url;
  return <Seo title={title.text} description={excerpt} image={image} />;
};

export default withPrismicPreview(BlogPostTemplate);

export const blogPostQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
      }
    }
    prismicPost(id: {eq: $id}) {
      _previewable
      url
      uid
      tags
      data {
        post_date
        last_update_date
        excerpt
        title {
          text
        }
        cover {
          url
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        body {
          ... on PrismicPostDataBodyChart {
            primary {
              chart_title
              chart_type
              chart_data
              chart_labels
              index_axis
              display_legend
              legend_label
              legend_position
              labels_anchor
              labels_alignment
              labels_offset
              scale_x_display
              scale_x_offset
              scale_x_grid
              scale_x_border
              scale_y_display
              scale_y_offset
              scale_y_grid
              scale_y_border
              primary_color
              secondary_color
            }
            slice_type
          }
          ... on PrismicPostDataBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostDataBodyEmbedVideo {
            id
            slice_type
            items {
              embed_code {
                text
              }
            }
          }
          ... on PrismicPostDataBodyImage {
            id
            primary {
              image_content {
                html
              }
            }
            slice_type
            items {
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              caption {
                html
              }
            }
          }
          ... on PrismicPostDataBodyTable {
            id
            primary {
              driver_standings_content {
                html
              }
              bottom_link{ uid }
              bottom_link_label
            }
            slice_type
            items {
              drivers
              teams
              points
              evolution
            }
          }
          ... on PrismicPostDataBodyRaceResults {
            id
            primary {
              race_result_content {
                html
              }
              bottom_link{ uid }
              bottom_link_label
            }
            slice_type
            items {
              drivers
              points
              teams
              time
              dq
              nc
              fastest_lap
            }
          }
          ... on PrismicPostDataBodyTechUpdates {
            id
            slice_type
            items {
              teams
              components
              type
              primary_reasons
            }
          }
        }
      }
    }
    previous: prismicPost(uid: {eq: $previousPostId}) {
      _previewable
      url
      data {
        title {
          text
        }
        cover {
          url
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
    next: prismicPost(uid: {eq: $nextPostId}) {
      _previewable
      url
      data {
        title {
          text
        }
        cover {
          url
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;